import React from 'react'

import Layout from '../components/layout'

const MiningPage = () => (
  <Layout>
    <div id="mining">
      <h1>Generalized Mining</h1>

      <h2>The Primer (Prague, November 2018)</h2>
      <p id="primer"><iframe src="https://www.youtube.com/embed/ceex9CN2YZU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="primer"></iframe></p>

      <h3>What is Generalized Mining?</h3>
      <p>
        <span style={{fontWeight: 'bold'}}>Generalized mining</span> (also:
        <a href="https://medium.com/@rzurrer/keepers-reboot-observations-forward-looking-trends-f0d3aae6d1c5">“keeping”</a>,
        “supply-side services”, and <a href="https://medium.com/notation-capital/notation-blockchain-mining-2-0-dcf46a28f7eb">“mining 2.0”</a>)
        refers to the cryptoeconomic games implemented by decentralized protocols that users can play to earn cryptocurrency-denominated compensation.
      </p>

      <p>Decentralized networks typically require hardware, software, or manual services in order to function. However,
        they cannot provide such services themselves (for reasons of both economics and decentralization) and therefore
        rely on users, investors, and other third parties for service provision. Decentralized networks make use of
        cryptoeconomic mechanisms to incentivize third parties, creating compensatory opportunities.  Examples of such
        services include transaction processing, staking, computational resource provision, software or <a href="https://forum.livepeer.org/t/introducing-the-merklemine/204">Merkle Mining</a>,
        content curation, market making, and more.</p>

        <p>Generalized mining has the potential to benefit all actors on a network, including providing better network
          operation to end users (the demand side) and earning incentives for service providers (the supply side). As
          decentralized networks become ubiquitous and the design space of cryptoeconomic mechanisms expands, the set of
          possible generalized mining activities grows and presents compensatory opportunities for protocol participants.
        </p>

      <h3>The Network Perspective</h3>
      <p>
        Decentralized networks need hardware to provision them, software to secure them, public stakeholders
        to govern them, and a long list of services to enable them to function in a trustless and distributed manner
        within their business domain. The very first such network, Bitcoin, incentivizes third parties ("miners")
        to provide security and transaction processing to the network through the process of mining, in exchange for
        minting new Bitcoin.
      </p>
      <p>
          Since the creation of Bitcoin, this trend has continued but has become unbundled. Today,
          networks incentivize the provision of many other kinds of services. One major area is
          resource provision in networks that enable decentralized computational services such as storage,
          computation, transcoding, rendering, and more. Another area is curation of content on social media
          networks such as Steemit or Relevant. Decentralized finance (<a href="http://defi.network">DeFi</a>) creates opportunities to provision
          liquidity in the form of lending, borrowing, market liquidity, and insurance.
      </p>
      <p>
        The concept of “active network participation” is the idea that investors in decentralized networks should
        also be users or participants. Doing so may increase the chances that the network is successful by influencing
        governance and <a href="https://medium.com/@cburniske/productive-capital-in-cryptonetworks-3bad74ad9bfb">making capital productive</a>.
      </p>

      <h2>Resources on Generalized Mining</h2>

      <h3>Compiled Resources</h3>
      <ul>
        <li><a href="https://messari.io/resource/generalized-mining">Messari: Generalized Mining</a></li>
      </ul>

      <h3>Blog Posts</h3>
      <ul>
        <li><a href="https://blog.coinfund.io/livepeer-cryptoeconomics-as-a-case-study-of-active-participation-in-decentralized-networks-19a932415e0e">Livepeer cryptoeconomics as a case study of active participation in decentralized networks</a></li>
        <li><a href="https://blog.coinfund.io/crypto-borrowing-and-staking-networks-e7d2d64a81a4">Crypto borrowing and staking networks</a></li>
        <li><a href="https://medium.com/@cburniske/productive-capital-in-cryptonetworks-3bad74ad9bfb">Productive capital in cryptonetworks</a> by <a href="http://placeholder.vc">Placeholder</a></li>
      </ul>

      <h3>Podcasts</h3>
      <ul>
        <li><a href="https://youtu.be/JdAO3zKlg8U">Epicenter Episode 256, “CoinFund: Crypto-Investing by Community Building”</a></li>
        <li><a href="https://unchainedpodcast.com/coinfunds-jake-brukhman-and-multicoins-tushar-jain-on-generalized-mining-ep-92/">Unchained: Episode 92, “CoinFund and Multicoin on Generalized Mining”</a></li>
      </ul>

      <h3>Videos</h3>
      <ul>
        <li><a href="https://www.youtube.com/embed/ceex9CN2YZU">Generalized Mining: Introduction and Primer</a></li>
        <li><a href="https://www.youtube.com/watch?v=dCVPhYxgvyo">D1Conf 2018: Generalized Mining and the Third-Party Economy</a></li>
        <li><a href="https://youtu.be/Cr6H2FcidjY">Devcon4 Side-Panel: Supply Side Services</a></li>
        <li><a href="https://youtu.be/zakQc07GRXA">Devcon4 Side-Panel: New Role of Crypto Investors</a></li>
        <li><a href="https://youtu.be/ydViUpTZens">Devcon4 Side-Panel: Staking Economic Design</a></li>
      </ul>

      <h3>Tweetstorms</h3>
      <ul >
        <li><a href="https://twitter.com/jbrukh/status/1055553854291562497">Defining Generalized Mining</a></li>
        <li><a href="https://twitter.com/jbrukh/status/1030946463893872640">Livepeer Token Economics</a></li>
      </ul>

    </div>
  </Layout>
)

export default MiningPage
